#evidon-prefdiag-navcontainer {
    background-color: #008e77 !important;
}

#_evidon-decline-button {
    color: #fff !important;
}

body {
    overflow: auto;
}
